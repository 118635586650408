import React from 'react';
import Seo from '../components/seo';

import Layout from '../components/layout';
import CustomNavTitle from '../components/CustomNavTitle';

import { theme } from '../styles/theme';
import packageInfo from '../../package.json';

const About = ({ location }) => {
  return (
    <Layout>
      <Seo title="About" />

      <CustomNavTitle title="About" destination="/" location={location} />

      <article css={theme.components.row} className="theme">
        <section className="max-w-content">
          <h3 css={theme.components.title}>About the content</h3>
          <p>The guidance for transfusion provided in this app is based on:</p>

          <ul>
            <li>
              NBTC Indication Codes for Transfusion in Adults – An Audit Tool
            </li>
            <li>NBTC Appropriate Specification of Emergency Red Cells</li>
            <li>
              BSH Guidelines for Transfusion for Fetuses, Neonates and Older
              Children
            </li>
            <li>
              BSH Guidelines for Pre-Transfusion Compatibility Procedures in
              Blood Transfusion Laboratories
            </li>
            <li>NICE guideline [NG24]</li>
          </ul>
          <p>
            These summary documents aim to act as a prompt for clinicians to
            facilitate appropriate use and to enable robust documentation of
            indications.
          </p>

          <p>
            Review of the NBTC Indication Codes for Transfusion is not
            anticipated before 2023. These are current guidelines and may change
            depending on new evidence.
          </p>

          <h3 className="pt-gap">About the app</h3>
          <p>Version Number: {packageInfo.version}</p>
          <p>
            This app was created by NHSBT PBM team, app design and production by
            HMA. The content of the App was developed with input from NBTC and
            the British Committee for Standards in Haematology.
          </p>

          <h3 className="pt-gap">Contact us</h3>
          <address>
            Customer Service
            <br />
            NHS Blood and Transplant,
            <br />
            Part Academic Block, Level 2,
            <br />
            John Radcliffe Hospital,
            <br />
            Headley Way, Headington,
            <br />
            Oxford OX3 9BQ
          </address>
          <p>
            Email:
            <a href="mailto:customerservice@nhsbt.nhs.uk">
              customerservice@nhsbt.nhs.uk
            </a>
          </p>

          <h3 className="pt-gap">References</h3>
          <p>
            NBTC indication codes:&nbsp;
            <a
              href="https://www.transfusionguidelines.org/uk-transfusion-committees/national-blood-transfusion-committee/responses-and-recommendations"
              className="text-theme">
              https://www.transfusionguidelines.org/uk-transfusion-committees/national-blood-transfusion-committee/responses-and-recommendations
            </a>
          </p>
          <p>
            NBTC Specification of Emergency Red Cells:&nbsp;
            <a
              href="https://www.transfusionguidelines.org/uk-transfusion-committees/national-blood-transfusion-committee/responses-and-recommendations"
              className="text-theme">
              https://www.transfusionguidelines.org/uk-transfusion-committees/national-blood-transfusion-committee/responses-and-recommendations
            </a>
          </p>
          <p>
            Guidelines on transfusion for fetuses, neonates and older children:
            &nbsp;
            <a
              href="https://b-s-h.org.uk/guidelines/guidelines/transfusion-for-fetuses-neonates-and-older-children/"
              className="text-theme">
              https://b-s-h.org.uk/guidelines/guidelines/transfusion-for-fetuses-neonates-and-older-children/
            </a>
          </p>
          <p>
            Guidelines for pre-transfusion compatibility procedures in blood
            transfusion laboratories&nbsp;
            <a
              href="http://www.b-s-h.org.uk/guidelines/guidelines/pre-transfusion-compatibility-procedures-in-blood-transfusion-laboratories"
              className="text-theme">
              http://www.b-s-h.org.uk/guidelines/guidelines/pre-transfusion-compatibility-procedures-in-blood-transfusion-laboratories
            </a>
          </p>
          <p>
            Blood transfusion NICE guidance [NG24]:&nbsp;
            <a
              href="https://www.nice.org.uk/guidance/ng24"
              className="text-theme">
              https://www.nice.org.uk/guidance/ng24
            </a>
          </p>

          <h3 className="pt-gap">Abbreviations (not previously explained)</h3>
          <p>
            <strong>NHSBT:</strong> NHS Blood and Transplant
          </p>
          <p>
            <strong>PBM:</strong> Patient Blood Management
          </p>
          <p>
            <strong>NBTC:</strong> National Blood Transfusion Committee
          </p>
          <p>
            <strong>NICE:</strong> National Institute for Health and Care
            Excellence
          </p>
          <p>
            <strong>BSH:</strong> British Society for Haematology
          </p>
          <p>
            <strong>SHOT:</strong> Serious Hazards of Transfusion
          </p>
          <p>
            <strong>SaBTO:</strong> advisory committee on the Safety of Blood,
            Tissues and Organs
          </p>
          <p>
            <strong>PHE:</strong> Public Health England
          </p>
          <p>
            <strong>GMC:</strong> General Medical Council
          </p>
          <p>
            <strong>SOP:</strong> Standard Operating Procedure
          </p>
          <p>
            <strong>LIMS:</strong> Laboratory Information Management System
          </p>
          <p>
            <strong>IAT:</strong> Indirect Antiglobulin Technique
          </p>
          <p>
            <strong>vCJD:</strong> Variant Creutzfeldt-Jakob disease
          </p>
          <p>
            <strong>TACO:</strong> Transfusion Associated Circulatory Overload
          </p>
          <p>
            <strong>HPA:</strong> Human Platelet Antigen
          </p>
          <p>
            <strong>CMV:</strong> Cytomegalovirus
          </p>
          <p>
            <strong>VKA:</strong> Vitamin K Antagonist
          </p>
          <p>
            <strong>Hb:</strong> Haemoglobin
          </p>
          <p>
            <strong>INR:</strong> International Normalised Ratio
          </p>
          <p>
            <strong>PT:</strong> Prothrombin Time
          </p>
          <p>
            <strong>APTT:</strong> Activated Partial Thromboplastin Time
          </p>
          <p>
            <strong>DIC:</strong> Disseminated Intravascular Coagulation
          </p>
        </section>
      </article>
    </Layout>
  );
};

export default About;
